import 'react-perfect-scrollbar/dist/css/styles.css';

import React from 'react';
import { navigate } from 'gatsby';
import { Button, Container, Grid } from '@material-ui/core';
import SEO from 'src/components/seo';

import ROICalculator from 'src/features/roiCalculator';

export default function IndexPage() {
  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <SEO title="Home" />

      <Container maxWidth="xs">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate('/onboarding');
                // navigate('/onboarding/?plan=s2l-monthly-starter-2020');
              }}
            >
              On boarding
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate('/login');
              }}
            >
              Login
            </Button>
          </Grid>

          <Grid item xs={12}>
            <ROICalculator />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
