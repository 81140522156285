/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-case-declarations */
import React, { useState } from 'react';
import numeral from 'numeral';
import {
  makeStyles,
  Button,
  Dialog,
  IconButton,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  OutlinedInput,
  Slider,
  withStyles,
  Grid,
} from '@material-ui/core';

import TooltipedText from 'src/components/tooltipedText';
import CloseIcon from 'src/assets/icons/close.svg';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    '& .MuiDialog-paper': {
      padding: theme.spacing(6, 10),
    },
  },
  header: {
    display: 'flex',
  },
  titleContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  titleText: {
    fontSize: 22,
    fontFamily: 'Lato',
  },
  tableHeader: {
    '&  th': {
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: 16,
      border: 'unset',
    },
    '&  th:nth-child(3)': {
      color: theme.palette.primary.main,
    },
  },
  table: {
    minWidth: 1100,
  },
  tableBody: {
    '& td': {
      borderBottom: '1px solid rgba(230, 230, 230, 0.7)',
      padding: theme.spacing(3),
    },
    '& td:nth-child(1)': {
      backgroundColor: '#F2F2F2',
      width: 460,
      fontWeight: 400,
    },
    '& td:nth-child(2)': {
      backgroundColor: '#E7E7E7',
      width: 90,
    },
    '& td:nth-child(3)': {
      backgroundColor: '#ECECEC',
      //   width: '40%',
    },
    '& td:nth-child(4)': {
      backgroundColor: '#E7E7E7',
      width: 90,
    },
    '& tr:first-child': {
      '& td:first-child': {
        borderTopLeftRadius: 5,
      },
      '& td:last-child': {
        borderTopRightRadius: 5,
      },
    },
    '& tr:last-child': {
      '& td': {
        border: 'unset',
      },
      '& td:first-child': {
        borderBottomLeftRadius: 5,
      },
      '& td:last-child': {
        borderBottomRightRadius: 5,
      },
    },
  },
  tableFooter: {
    '&  th': {
      padding: theme.spacing(2, 3),
      fontWeight: 700,
      fontSize: 18,
      border: 'unset',
      color: theme.palette.primary.main,
    },
  },
  input: {
    width: 105,
    fontWeight: 700,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    '& input': {
      textAlign: 'center',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sliderLabel: {
    opacity: 0.5,
    fontSize: 12,
  },
  label: {
    opacity: 0.5,
  },
  tablebodySmallBold: {
    '& td': {
      borderBottom: '1px solid rgba(230, 230, 230, 0.7)',
      padding: theme.spacing(3),
    },
    '& td:nth-child(1)': {
      width: 300,
      fontWeight: 700,
      textAlign: 'right',
    },
    '& td:nth-child(2)': {
      width: 130,
      textAlign: 'right',
    },
    '& td:nth-child(3)': {
      width: 90,
      textAlign: 'right',
      color: '#CE3100',
      fontSize: 22,
      fontWeight: 700,
    },
    '& td:nth-child(4)': {
      //   width: '40%',
    },
    '& td:nth-child(5)': {
      width: 90,
      textAlign: 'right',
      color: theme.palette.primary.main,
      fontSize: 22,
    },

    '& tr:last-child': {
      '& td': {
        border: 'unset',
      },
    },
  },
  buttonWrapper2: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
}));

const CustomSlider = withStyles(theme => ({
  root: {
    color: '#C4C4C4',
    height: 4,
    // padding: '30px 0',
  },
  rail: {
    height: 4,
  },
  track: {
    height: 4,
  },
  thumb: {
    height: 30,
    width: 30,
    borderRadius: 5,
    marginTop: -13,
    marginLeft: -13,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    fontSize: 11,
  },
}))(Slider);

export default function () {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [state, setSate] = useState({
    totalNumber: { input1: 300 },
    percentLeads: { input1: 50, input2: 75 },
    avgCloseRate: { input1: 50, input2: 60 },
    avgLifetime: { input1: 3000 },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    switch (event.currentTarget.name) {
      case 'totalNumber':
        setSate({ ...state, totalNumber: { input1: event.target.value } });
        break;
      case 'percentLeads':
        setSate({
          ...state,
          percentLeads: {
            input1: event.target.value,
            input2: Number(event.target.value) + 50 > 100 ? 100 : Number(event.target.value) + 50,
          },
        });
        break;
      case 'avgCloseRate':
        setSate({
          ...state,
          avgCloseRate: {
            input1: event.target.value,
            input2: Number(event.target.value) + 20 > 100 ? 100 : Number(event.target.value) + 20,
          },
        });
        break;
      case 'avgLifetime':
        setSate({ ...state, avgLifetime: { input1: event.target.value } });
        break;
      default:
        break;
    }
  };

  const TotalPercentLeads = {
    withS2L: (state.totalNumber.input1 * state.percentLeads.input2) / 100,
    withoutS2L: (state.totalNumber.input1 * state.percentLeads.input1) / 100,
  };
  const TotalPercentageSpeak = {
    withS2L: (TotalPercentLeads.withS2L * state.avgCloseRate.input2) / 100,
    withoutS2L: (TotalPercentLeads.withoutS2L * state.avgCloseRate.input1) / 100,
  };

  const TotalNbrSales = {
    withS2L: TotalPercentageSpeak.withS2L,
    withoutS2L: TotalPercentageSpeak.withoutS2L,
  };

  const TotalRevenue = {
    withS2L: TotalNbrSales.withS2L * state.avgLifetime.input1,
    withoutS2L: TotalNbrSales.withoutS2L * state.avgLifetime.input1,
  };

  return (
    <>
      <div className={classes.buttonWrapper}>
        <Button variant="contained" color="primary" size="large" disableElevation onClick={handleOpen}>
          ROI calculator
        </Button>
      </div>
      <Dialog maxWidth="lg" open={open} className={classes.dialog}>
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            <Typography className={classes.titleText} color="secondary">
              ROI calculator
            </Typography>
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <TableContainer component={Paper} elevation={0}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell align="center">Without Leverly</TableCell>
                <TableCell align="center">Total</TableCell>
                <TableCell align="center" color="primary">
                  With Leverly
                </TableCell>
                <TableCell align="center">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              <TableRow>
                <TableCell>
                  <div className={classes.content}>
                    Total number of internet <br />
                    leads per month
                    <OutlinedInput
                      name="totalNumber"
                      defaultValue={state.totalNumber.input1}
                      className={classes.input}
                      onChange={handleChange}
                    />
                  </div>
                </TableCell>
                <TableCell align="center">{state.totalNumber.input1}</TableCell>
                <TableCell align="center" />
                <TableCell align="center">{state.totalNumber.input1}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className={classes.content}>
                    Percent of leads you
                    <br /> speak to
                    <OutlinedInput
                      name="percentLeads"
                      defaultValue={state.percentLeads.input1}
                      endAdornment={<div>%</div>}
                      className={classes.input}
                      onChange={handleChange}
                    />
                  </div>
                </TableCell>
                <TableCell align="center">{TotalPercentLeads.withoutS2L}</TableCell>
                <TableCell align="center">
                  <Typography className={classes.label}>Speak to way more leads with Leverly</Typography>
                  <Grid container spacing={3} style={{ alignItems: 'center' }}>
                    <Grid item>
                      <Typography variant="body2" className={classes.sliderLabel}>
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <CustomSlider
                        ThumbComponent={sliderProps => <span {...sliderProps}>{state.percentLeads.input2}%</span>}
                        min={0}
                        max={100}
                        step={1}
                        value={state.percentLeads.input2}
                        onChange={(event, newValue) => {
                          setSate({
                            ...state,
                            percentLeads: { ...state.percentLeads, input2: newValue },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.sliderLabel}>
                        100%
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center">{TotalPercentLeads.withS2L}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className={classes.content}>
                    Average close rate
                    <OutlinedInput
                      name="avgCloseRate"
                      defaultValue={state.avgCloseRate.input1}
                      endAdornment={<div>%</div>}
                      className={classes.input}
                      onChange={handleChange}
                    />
                  </div>
                </TableCell>
                <TableCell align="center">{TotalPercentageSpeak.withoutS2L}</TableCell>
                <TableCell align="center">
                  <Grid container spacing={3} style={{ alignItems: 'center' }}>
                    <Grid item>
                      <Typography variant="body2" className={classes.sliderLabel}>
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <CustomSlider
                        ThumbComponent={sliderProps => <span {...sliderProps}>{state.avgCloseRate.input2}%</span>}
                        min={0}
                        max={100}
                        step={1}
                        value={state.avgCloseRate.input2}
                        onChange={(event, newValue) => {
                          setSate({
                            ...state,
                            avgCloseRate: { ...state.avgCloseRate, input2: newValue },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.sliderLabel}>
                        100%
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center">{TotalPercentageSpeak.withS2L}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className={classes.content}>
                    Average Lifetime value <br />
                    per customer
                    <OutlinedInput
                      name="avgLifetime"
                      defaultValue={3000}
                      startAdornment={<div>$</div>}
                      className={classes.input}
                      onChange={handleChange}
                    />
                  </div>
                </TableCell>
                <TableCell align="center">${state.avgLifetime.input1}</TableCell>
                <TableCell align="center" />
                <TableCell align="center">${state.avgLifetime.input1}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.buttonWrapper2}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              setCalculated(true);
            }}
          >
            calculate
          </Button>
        </div>
        {calculated && (
          <TableContainer>
            <Table className={classes.table}>
              <TableBody className={classes.tablebodySmallBold}>
                <TableRow>
                  <TableCell>TOTAL NUMBER OF NEW SALES</TableCell>
                  <TableCell align="center" />
                  <TableCell>{TotalNbrSales.withoutS2L}</TableCell>
                  <TableCell />
                  <TableCell>{TotalNbrSales.withS2L}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>TOTAL REVENUE</TableCell>
                  <TableCell align="center" />
                  <TableCell>{numeral(TotalRevenue.withoutS2L).format('$0,0.00')}</TableCell>
                  <TableCell />
                  <TableCell>{numeral(TotalRevenue.withS2L).format('$0,0.00')}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Dialog>
    </>
  );
}
